import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import { CssVarsProvider } from '@mui/joy/styles';
import { getJoyDesignTokens } from '../theme/joyTheme'; // Importa il tema Joy
import SchoolIcon from '@mui/icons-material/School';
import PlaceIcon from '@mui/icons-material/Place';

export default function AboutUs({ mode, toggleColorMode }) {
  const joyTheme = useMemo(() => getJoyDesignTokens(mode), [mode]);
  return (
    <Container
      id="aboutus"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: 'text.primary',
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        About Us
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Typography
          sx={{
            textAlign: 'center',
            color: 'text.secondary',
            width: { sm: '100%', md: '100%' },
          }}
        >
         Our startup aims to revolutionize team dynamics in companies, focusing on transforming how teams work together to achieve maximum efficiency.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: {xs: 'column', sm: 'column', md: 'row', lg: 'row'},
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            mt: 4,
          }}
        >
          <CssVarsProvider theme={joyTheme}>
            <Card sx={{ minHeight: '280px', width: 320 }}>
              <CardCover>
                <img
                  src="https://staffmotion-images.s3.eu-north-1.amazonaws.com/Team/ALGIO.jpeg"
                  srcSet="https://staffmotion-images.s3.eu-north-1.amazonaws.com/Team/ALGIO.jpeg 2x"
                  loading="lazy"
                  alt=""
                />
              </CardCover>
              <CardCover
                sx={{
                  background:
                    'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 160px)',
                }}
              />
              <CardContent sx={{ justifyContent: 'flex-end' }}>
                <Typography variant="h4" color='#fff'>
                  Andrea Algisi
                </Typography>
                <Typography variant="h5" color='#fff'>
                  CEO
                </Typography>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 1,
                  mt: 1,
                }}>
                  <SchoolIcon sx={{ color: "white" }} />
                  <Typography variant="h6" color='#fff'>Banking and Finance</Typography>
                </Box>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 1,
                }}>
                  <PlaceIcon sx={{ color: "white" }} />
                  <Typography variant="h6" color='#fff'>University of Brescia</Typography>
                </Box>
              </CardContent>
            </Card>
            <Card sx={{ minHeight: '280px', width: 320 }}>
              <CardCover>
                <img
                  src="https://staffmotion-images.s3.eu-north-1.amazonaws.com/Team/DALE.jpeg"
                  srcSet="https://staffmotion-images.s3.eu-north-1.amazonaws.com/Team/DALE.jpeg 2x"
                  loading="lazy"
                  alt=""
                />
              </CardCover>
              <CardCover
                sx={{
                  background:
                    'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 160px)',
                }}
              />
              <CardContent sx={{ justifyContent: 'flex-end' }}>
                <Typography variant="h4" color='#fff'>
                  Tommaso D'Alessio
                </Typography>
                <Typography variant="h5" color='#fff'>
                  CAIO
                </Typography>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 1,
                  mt: 1,
                }}>
                  <SchoolIcon sx={{ color: "white" }} />
                  <Typography variant="h6" color='#fff'>Engineering of Computing System</Typography>
                </Box>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 1,
                }}>
                  <PlaceIcon sx={{ color: "white" }} />
                  <Typography variant="h6" color='#fff'>Polytechnic University of Milan</Typography>
                </Box>
              </CardContent>
            </Card>
            <Card sx={{ minHeight: '280px', width: 320 }}>
              <CardCover>
                <img
                  src="https://staffmotion-images.s3.eu-north-1.amazonaws.com/Team/SANNA.jpeg"
                  srcSet="https://staffmotion-images.s3.eu-north-1.amazonaws.com/Team/SANNA.jpeg 2x"
                  loading="lazy"
                  alt=""
                />
              </CardCover>
              <CardCover
                sx={{
                  background:
                    'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 160px)',
                }}
              />
              <CardContent sx={{ justifyContent: 'flex-end' }}>
                <Typography variant="h4" color='#fff'>
                  Andrea Lussana
                </Typography>
                <Typography variant="h5" color='#fff'>
                  CTO
                </Typography>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 1,
                  mt: 1,
                }}>
                  <SchoolIcon sx={{ color: "white" }} />
                  <Typography variant="h6" color='#fff'>Cybersecurity</Typography>
                </Box>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 1,
                }}>
                  <PlaceIcon sx={{ color: "white" }} />
                  <Typography variant="h6" color='#fff'>University of Milan</Typography>
                </Box>
              </CardContent>
            </Card>
          </CssVarsProvider>
        </Box>
      </Box>
    </Container>
  );
}
