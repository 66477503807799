import React, { useMemo, useState } from 'react';
import { Box, Container, Typography, List, ListItem, Link } from '@mui/material';
import { CssVarsProvider } from '@mui/joy/styles';
import { getJoyDesignTokens } from '../theme/joyTheme'; // Importa il tema Joy
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


function createData(
    category: string,
    examples: string,
    collected: string,
) {
    return { category, examples, collected };
}

const rows = [
    createData('A. Identifiers', 'Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name', 'NO'),
    createData('B. Personal information as defined in the California Customer Records statute', 'Name, contact information, education, employment, employment history, and financial information', 'NO'),
    createData('C. Protected classification characteristics under state or federal law', 'Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data', 'NO'),
    createData('D. Commercial information', 'Transaction information, purchase history, financial details, and payment information', 'NO'),
    createData('E. Biometric information', 'Fingerprints and voiceprints', 'NO'),
    createData('F. Internet or other similar network activity', 'Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements', 'NO'),
    createData('G. Geolocation data', 'Device location', 'NO'),
    createData('H. Audio, electronic, sensory, or similar information', 'Images and audio, video or call recordings created in connection with our business activities', 'NO'),
    createData('I. Professional or employment-related information', 'Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us', 'NO'),
    createData('J. Education Information', 'Student records and directory information', 'NO'),
    createData('K. Inferences drawn from collected personal information', 'Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics', 'YES'),
    createData('L. Sensitive personal Information', 'Biometric data', 'YES'),
];


export default function PrivacyPolicy({ mode, toggleColorMode }) {
    const joyTheme = useMemo(() => getJoyDesignTokens(mode), [mode]);

    return (
        <Box
            id="hero"
            sx={(theme) => ({
                width: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundImage:
                    'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
                ...theme.applyStyles('dark', {
                    backgroundImage:
                        'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
                }),
            })}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start', // Posiziona la seconda Typography a sinistra
                    pt: { xs: 20, sm: 20 },
                    pb: { xs: 15, sm: 30 },
                }}
            >
                <Typography variant="h1" sx={{ mb: 2, width: '100%', textAlign: 'center' }}>Privacy Policy</Typography>
                <Typography variant='subtitle2' sx={{ mb: 2, width: '100%', textAlign: { xs: 'center', sm: 'center', md: 'right', lg: 'right', xl: 'right' }, color: "#A1A1A1" }}>Last Updated: November 14, 2024</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>This Privacy Policy for StaffMotion ("we," "us," or "our"), describes how and why we might access, collect, store, use, and/or share ("process") your personal information when you use our services ("Services"), including when you:</Typography>
                <List sx={{ listStyleType: 'disc', ml: { xs: 0, sm: 0, md: 5, lg: 5, xl: 5 } }}>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Visit our website at <Link href="https://www.staffmotion.eu" color="text.secondary">https://www.staffmotion.eu</Link>, or any website of ours that links to this Privacy Policy</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Engage with us in other related ways, including any sales, marketing, or events</Typography></ListItem>
                </List>
                <Typography variant='subtitle2' sx={{ mb: 4 }}>Questions or concerns? Reading this Privacy Policy will help you understand your privacy rights and choices. We are responsible for making decisions about how your personal information is processed. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at <Link color="text.secondary" variant="body2" target="_top"
                    rel="noopener noreferrer"
                    href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{process.env.REACT_APP_SUPPORT_EMAIL}</Link>.

                </Typography>
                <Typography variant='h5' sx={{ mb: 1 }}>SUMMARY OF KEY POINTS</Typography>
                <Typography variant='subtitle2' sx={{ mb: 3 }}>This summary provides key points from our Privacy Policy, but you can find out more details about any of these topics throughout the article.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}><span style={{ fontStyle: "italic", fontWeight: "bold" }}>What personal information do we process? </span>When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about personal information.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}><span style={{ fontStyle: "italic", fontWeight: "bold" }}>Do we process any sensitive personal information? </span>Some of the information may be considered "special" or "sensitive" in certain jurisdictions, for example your racial or ethnic origins, sexual orientation, and religious beliefs. We may process sensitive personal information when necessary with your consent or as otherwise permitted by applicable law.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}><span style={{ fontStyle: "italic", fontWeight: "bold" }}>Do we collect any information from third parties? </span>We do not collect any information from third parties.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}><span style={{ fontStyle: "italic", fontWeight: "bold" }}>How do we process your information? </span>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}><span style={{ fontStyle: "italic", fontWeight: "bold" }}>In what situations and with which parties do we share personal information? </span>We may share information in specific situations and with specific third parties.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}><span style={{ fontStyle: "italic", fontWeight: "bold" }}>How do we keep your information safe? </span>We have adequate organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}><span style={{ fontStyle: "italic", fontWeight: "bold" }}>What are your rights? </span>Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}><span style={{ fontStyle: "italic", fontWeight: "bold" }}>How do you exercise your rights? </span>The easiest way to exercise your rights is by submitting a data subject access request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</Typography>


                <Typography variant='h5' sx={{ mb: 1, mt: 3 }}>1. WHAT INFORMATION DO WE COLLECT?</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}><span style={{ fontWeight: "bold" }}>Personal information you disclose to us</span></Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontStyle: "italic" }}>In Short: We collect personal information that you provide to us.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}><span style={{ fontStyle: "italic", fontWeight: "bold" }}>Personal Information Provided by You.</span> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</Typography>
                <List sx={{ listStyleType: 'disc', ml: { xs: 0, sm: 0, md: 5, lg: 5, xl: 5 } }}>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>names</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>phone numbers</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>email addresses</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>job titles</Typography></ListItem>
                </List>
                <Typography variant='subtitle2' sx={{ mb: 1 }}><span style={{ fontStyle: "italic", fontWeight: "bold" }}>Sensitive Information.</span> When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information:</Typography>
                <List sx={{ listStyleType: 'disc', ml: { xs: 0, sm: 0, md: 5, lg: 5, xl: 5 } }}>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>biometric data</Typography></ListItem>
                </List>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</Typography>



                <Typography variant='h5' sx={{ mb: 1, mt: 3 }}>2. HOW DO WE PROCESS YOUR INFORMATION?</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontStyle: "italic" }}>In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</Typography>
                <List sx={{ listStyleType: 'disc', ml: { xs: 0, sm: 0, md: 5, lg: 5, xl: 5 } }}>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>To save or protect an individual's vital interest. We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</Typography></ListItem>
                </List>


                <Typography variant='h5' sx={{ mb: 1, mt: 3 }}>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontStyle: "italic" }}>In Short: We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontStyle: "italic", textDecoration: "underline" }}>If you are located in the EU or UK, this section applies to you.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</Typography>
                <List sx={{ listStyleType: 'disc', ml: { xs: 0, sm: 0, md: 5, lg: 5, xl: 5 } }}>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Consent. We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time by contacting us here: <Link color="text.secondary" variant="body2" target="_top"
                        rel="noopener noreferrer"
                        href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{process.env.REACT_APP_SUPPORT_EMAIL}</Link>.</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Performance of a Contract. We may process your personal information when we believe it is necessary to fulfill our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Legal Obligations. We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</Typography></ListItem>
                </List>
                <Typography variant='subtitle2' sx={{ mb: 1, fontStyle: "italic", textDecoration: "underline" }}>If you are located in Canada, this section applies to you.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can withdraw your consent at any time by contacting us here: <Link color="text.secondary" variant="body2" target="_top"
                    rel="noopener noreferrer"
                    href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{process.env.REACT_APP_SUPPORT_EMAIL}</Link></Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:</Typography>
                <List sx={{ listStyleType: 'disc', ml: { xs: 0, sm: 0, md: 5, lg: 5, xl: 5 } }}>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>For investigations and fraud detection and prevention</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>For business transactions provided certain conditions are met</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>For identifying injured, ill, or deceased persons and communicating with next of kin</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>If the collection is solely for journalistic, artistic, or literary purposes</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>If the information is publicly available and is specified by the regulations</Typography></ListItem>
                </List>

                <Typography variant='h5' sx={{ mb: 1, mt: 3 }}>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontStyle: "italic" }}>In Short: We may share information in specific situations described in this section and/or with the following third parties.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>We may need to share your personal information in the following situations:</Typography>
                <List sx={{ listStyleType: 'disc', ml: { xs: 0, sm: 0, md: 5, lg: 5, xl: 5 } }}>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</Typography></ListItem>
                </List>

                <Typography variant='h5' sx={{ mb: 1, mt: 3 }}>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontStyle: "italic" }}>In Short: We may use cookies and other tracking technologies to collect and store your information.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services and your account, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>To the extent these online tracking technologies are deemed to be a "sale"/"sharing" (which includes targeted advertising, as defined under the applicable laws) under applicable US state laws, you can opt out of these online tracking technologies by submitting a request as described below under section "12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?"</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</Typography>


                <Typography variant='h5' sx={{ mb: 1, mt: 3 }}>6. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontStyle: "italic" }}>In Short: We offer products, features, or tools powered by artificial intelligence, machine learning, or similar technologies.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>As part of our Services, we offer products, features, or tools powered by artificial intelligence, machine learning, or similar technologies (collectively, "AI Products"). These tools are designed to enhance your experience and provide you with innovative solutions. The terms in this Privacy Notice govern your use of the AI Products within our Services.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontWeight: "bold" }}>Our AI Products</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Our AI Products are designed for the following functions:</Typography>
                <List sx={{ listStyleType: 'disc', ml: { xs: 0, sm: 0, md: 5, lg: 5, xl: 5 } }}>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Image analysis</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Machine learning models</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Video analysis</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Text analysis</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>AI predictive analytics</Typography></ListItem>
                </List>
                <Typography variant='subtitle2' sx={{ mb: 1, fontWeight: "bold" }}>How We Process Your Data Using AI</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>All personal information processed using our AI Products is handled in line with our Privacy Policy and our agreement with third parties. This ensures high security and safeguards your personal information throughout the process, giving you peace of mind about your data's safety.</Typography>


                <Typography variant='h5' sx={{ mb: 1, mt: 3 }}>7. HOW LONG DO WE KEEP YOUR INFORMATION?</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontStyle: "italic" }}>In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this Privacy Notice unless otherwise required by law.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>We will only keep your personal information for as long as it is necessary for the purposes set out in this Privacy Notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</Typography>



                <Typography variant='h5' sx={{ mb: 1, mt: 3 }}>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontStyle: "italic" }}>In Short: We aim to protect your personal information through a system of organizational and technical security measures.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</Typography>




                <Typography variant='h5' sx={{ mb: 1, mt: 3 }}>9. DO WE COLLECT INFORMATION FROM MINORS?</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontStyle: "italic" }}>In Short: We do not knowingly collect data from or market to children under 18 years of age.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <Link color="text.secondary" variant="body2" target="_top"
                    rel="noopener noreferrer"
                    href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{process.env.REACT_APP_SUPPORT_EMAIL}</Link>.</Typography>





                <Typography variant='h5' sx={{ mb: 1, mt: 3 }}>10. WHAT ARE YOUR PRIVACY RIGHTS?</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontStyle: "italic" }}>In Short: Depending on your state of residence in the US or in some regions, such as the European Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section "15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>We will consider and act upon any request in accordance with applicable data protection laws.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your <Link href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" target="_blank" rel="noopener noreferrer">Member State data protection authority</Link> or <Link href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/" target="_blank" rel="noopener noreferrer">UK data protection authority</Link>.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>If you are located in Switzerland, you may contact the <Link href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank" rel="noopener noreferrer">Federal Data Protection and Information Commissioner</Link>.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}></Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below or updating your preferences.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontWeight: "bold" }}>Account Information</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>If you would at any time like to review or change the information in your account or terminate your account, you can:</Typography>
                <List sx={{ listStyleType: 'disc', ml: { xs: 0, sm: 0, md: 5, lg: 5, xl: 5 } }}>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Contact us using the contact information provided.</Typography></ListItem>
                </List>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. </Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>If you have questions or comments about your privacy rights, you may email us at <Link color="text.secondary" variant="body2" target="_top"
                    rel="noopener noreferrer"
                    href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{process.env.REACT_APP_SUPPORT_EMAIL}</Link>.</Typography>




                <Typography variant='h5' sx={{ mb: 1, mt: 3 }}>11. CONTROLS FOR DO-NOT-TRACK FEATURES</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Notice.

                </Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>California law requires us to let you know how we respond to web browser DNT signals. Because there currently is not an industry or legal standard for recognizing or honoring DNT signals, we do not respond to them at this time.</Typography>




                <Typography variant='h5' sx={{ mb: 1, mt: 3 }}>12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontStyle: "italic" }}>In Short: If you are a resident of California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky, Minnesota, Montana, Nebraska, New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia, you may have the right to request access to and receive details about the personal information we maintain about you and how we have processed it, correct inaccuracies, get a copy of, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. More information is provided below.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontWeight: "bold" }}>Categories of Personal Information We Collect</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>We have collected the following categories of personal information in the past twelve (12) months:</Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Category</TableCell>
                                <TableCell>Examples</TableCell>
                                <TableCell>Collected</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.category}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.category}
                                    </TableCell>
                                    <TableCell>{row.examples}</TableCell>
                                    <TableCell>{row.collected}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>We only collect sensitive personal information, as defined by applicable privacy laws or the purposes allowed by law or with your consent. Sensitive personal information may be used, or disclosed to a service provider or contractor, for additional, specified purposes. You may have the right to limit the use or disclosure of your sensitive personal information.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:</Typography>
                <List sx={{ listStyleType: 'disc', ml: { xs: 0, sm: 0, md: 5, lg: 5, xl: 5 } }}>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Receiving help through our customer support channels;</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Participation in customer surveys or contests; </Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Facilitation in the delivery of our Services and to respond to your inquiries.</Typography></ListItem>
                </List>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>We will use and retain the collected personal information as needed to provide the Services or for:</Typography>
                <List sx={{ listStyleType: 'disc', ml: { xs: 0, sm: 0, md: 5, lg: 5, xl: 5 } }}>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Category K - As long as the user has an account with us</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Category L - As long as the user has an account with us</Typography></ListItem>
                </List>
                <Typography variant='subtitle2' sx={{ mb: 1, fontWeight: "bold" }}>Sources of Personal Information</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Learn more about the sources of personal information we collect in "1. WHAT INFORMATION DO WE COLLECT?"</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontWeight: "bold" }}>How We Use and Share Personal Information</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Learn more about how we use your personal information in the section, "2. HOW DO WE PROCESS YOUR INFORMATION?"</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Will your information be shared with anyone else?</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information to in the section, "4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We will not sell or share personal information in the future belonging to website visitors, users, and other consumers.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontWeight: "bold" }}>Your Rights</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>You have rights under certain US state data protection laws. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law. These rights include:</Typography>
                <List sx={{ listStyleType: 'disc', ml: { xs: 0, sm: 0, md: 5, lg: 5, xl: 5 } }}>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Right to know whether or not we are processing your personal data</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Right to access your personal data</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Right to correct inaccuracies in your personal data</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Right to request the deletion of your personal data</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Right to obtain a copy of the personal data you previously shared with us</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Right to non-discrimination for exercising your rights</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Right to opt out of the processing of your personal data if it is used for targeted advertising (or sharing as defined under California’s privacy law), the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")</Typography></ListItem>
                </List>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Depending upon the state where you live, you may also have the following rights:</Typography>
                <List sx={{ listStyleType: 'disc', ml: { xs: 0, sm: 0, md: 5, lg: 5, xl: 5 } }}>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Right to access the categories of personal data being processed (as permitted by applicable law, including Minnesota’s privacy law)</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Right to obtain a list of the categories of third parties to which we have disclosed personal data (as permitted by applicable law, including California's and Delaware's privacy law)</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Right to obtain a list of specific third parties to which we have disclosed personal data (as permitted by applicable law, including Minnesota's and Oregon's privacy law)</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Right to review, understand, question, and correct how personal data has been profiled (as permitted by applicable law, including Minnesota’s privacy law)</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Right to limit use and disclosure of sensitive personal data (as permitted by applicable law, including California’s privacy law)</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>Right to opt out of the collection of sensitive data and personal data collected through the operation of a voice or facial recognition feature (as permitted by applicable law, including Florida’s privacy law)</Typography></ListItem>
                </List>
                <Typography variant='subtitle2' sx={{ mb: 1, fontWeight: "bold" }}>How to Exercise Your Rights</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>To exercise these rights, you can contact us by emailing us at <Link color="text.secondary" variant="body2" target="_top"
                    rel="noopener noreferrer"
                    href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{process.env.REACT_APP_SUPPORT_EMAIL}</Link>, or by referring to the contact details at the bottom of this document.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Under certain US state data protection laws, you can designate an authorized agent to make a request on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with applicable laws.

                </Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontWeight: "bold" }}>Request Verification</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. We will only use personal information provided in your request to verify your identity or authority to make the request. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request and the agent will need to provide a written and signed permission from you to submit such request on your behalf.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontWeight: "bold" }}>Appeals</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Under certain US state data protection laws, if we decline to take action regarding your request, you may appeal our decision by emailing us at support@staffmotion.eu. We will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may submit a complaint to your state attorney general.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontWeight: "bold" }}>California "Shine The Light" Law</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us by using the contact details provided in the section "15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"</Typography>




                <Typography variant='h5' sx={{ mb: 1, mt: 3 }}>13. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontStyle: "italic" }}>In Short: You may have additional rights based on the country you reside in.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontWeight: "bold" }}>Australia and New Zealand</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>We collect and process your personal information under the obligations and conditions set by Australia's Privacy Act 1988 and New Zealand's Privacy Act 2020 (Privacy Act).</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>This Privacy Notice satisfies the notice requirements defined in both Privacy Acts, in particular: what personal information we collect from you, from which sources, for which purposes, and other recipients of your personal information.

                </Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>If you do not wish to provide the personal information necessary to fulfill their applicable purpose, it may affect our ability to provide our services, in particular:
                    <List sx={{ listStyleType: 'disc', ml: { xs: 0, sm: 0, md: 5, lg: 5, xl: 5 } }}>
                        <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>offer you the products or services that you want</Typography></ListItem>
                        <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>respond to or help with your requests</Typography></ListItem>
                        <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>manage your account with us</Typography></ListItem>
                        <ListItem sx={{ display: 'list-item' }}><Typography variant='subtitle2'>confirm your identity and protect your account</Typography></ListItem>

                    </List>
                </Typography>

                <Typography variant='subtitle2' sx={{ mb: 1 }}>At any time, you have the right to request access to or correction of your personal information. You can make such a request by contacting us by using the contact details provided in the section "16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?"

</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>If you believe we are unlawfully processing your personal information, you have the right to submit a complaint about a breach of the Australian Privacy Principles to the <Link href="https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us" target="_blank" rel="noopener noreferrer">Office of the Australian Information Commissioner</Link> and a breach of New Zealand's Privacy Principles to the <Link href="https://www.privacy.org.nz/your-rights/making-a-complaint/" target="_blank" rel="noopener noreferrer">Office of New Zealand Privacy Commissioner</Link>.</Typography>




                <Typography variant='h5' sx={{ mb: 1, mt: 3 }}>14. DO WE MAKE UPDATES TO THIS NOTICE?</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1, fontStyle: "italic" }}>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>We may update this Privacy Notice from time to time. The updated version will be indicated by an updated "Revised" date at the top of this Privacy Notice. If we make material changes to this Privacy Notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this Privacy Notice frequently to be informed of how we are protecting your information.</Typography>




                <Typography variant='h5' sx={{ mb: 1, mt: 3 }}>15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>If you have questions or comments about this notice, you may email us at <Link color="text.secondary" variant="body2" target="_top"
                    rel="noopener noreferrer"
                    href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{process.env.REACT_APP_SUPPORT_EMAIL}</Link></Typography>




                <Typography variant='h5' sx={{ mb: 1, mt: 3 }}>16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</Typography>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Based on the applicable laws of your country or state of residence in the US, you may have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please contact us: <Link color="text.secondary" variant="body2" target="_top"
                    rel="noopener noreferrer"
                    href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{process.env.REACT_APP_SUPPORT_EMAIL}</Link></Typography>
            </Container>

        </Box>
    );
}
