import React, { useMemo, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { CssVarsProvider } from '@mui/joy/styles';
import { getJoyDesignTokens } from '../theme/joyTheme'; // Importa il tema Joy
import { TextField, Button, Alert, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // Assicurati di avere un AuthContext per gestire l'autenticazione
import { Navigate } from 'react-router-dom';
import Snackbar from '@mui/joy/Snackbar';

export default function Login({ mode, toggleColorMode }) {
    const joyTheme = useMemo(() => getJoyDesignTokens(mode), [mode]);

    const [email, setEmail] = useState('');
    const [uuid, setUuid] = useState('');
    const [result, setResult] = useState('');
    const [error, setError] = useState(''); // Gestione degli errori
    const [left, setLeft] = React.useState();

    const navigate = useNavigate();
    const { login } = useAuth(); // Funzione di login dal contesto di autenticazione

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://1cfup748w7.execute-api.eu-north-1.amazonaws.com/getAuthDemo', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, uuid }),
            });

            const data = await response.json();

            if (data.success) {
                // Imposta il token e i dati utente nel contesto
                login({ token: data.token, user: { email, uuid } });
                localStorage.setItem('authToken', data.token);
                setResult('Valid user!');
                navigate('/demo'); // Reindirizza l'utente alla pagina demo
            } else {
                setError('Invalid credentials');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred during login');
        }
    };

    return (
        <Box
            id="hero"
            sx={(theme) => ({
                width: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundImage:
                    'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
                ...theme.applyStyles('dark', {
                    backgroundImage:
                        'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
                }),
            })}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: { xs: 20, sm: 40 },
                    pb: { xs: 15, sm: 30 },
                }}
            >
                <Typography variant="h4" align="center" gutterBottom>
                    Demo Login
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        placeholder="Email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <TextField
                        placeholder="Password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={uuid}
                        onChange={(e) => setUuid(e.target.value)}
                        required
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Login
                    </Button>
                </form>
            </Container >
            <CssVarsProvider theme={joyTheme}>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    variant="solid"
                    color="danger"
                    autoHideDuration={5000}
                    resumeHideDuration={left}
                    onUnmount={() => setLeft(undefined)}
                    open={error}
                    onClose={() => {
                        setError(false);
                    }}
                >
                    {error}
                </Snackbar>
            </CssVarsProvider>
        </Box>
    );
}
