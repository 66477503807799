import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/joy/Stepper';
import Step, { stepClasses } from '@mui/joy/Step';
import StepIndicator, { stepIndicatorClasses } from '@mui/joy/StepIndicator';
import Typography, { typographyClasses } from '@mui/material/Typography';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import PieChartIcon from '@mui/icons-material/PieChart';
import { CssVarsProvider} from '@mui/joy/styles';

const UploadStepper = ({
    file,
    joyTheme,
    mode,
    started,
    step1completed,
    step2completed,
    step3completed,
    step4completed,
    setStep1completed,
    setStep2completed,
    setStep3completed,
    setStep4completed
}) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            ml: file?.name ? '-100px' : '0px',
        }}>
            <CssVarsProvider theme={joyTheme}>
                <Stepper
                    orientation="vertical"
                    sx={(theme) => ({
                        '--Stepper-verticalGap': '2.5rem',
                        '--StepIndicator-size': '2.5rem',
                        '--Step-gap': '1rem',
                        '--Step-connectorInset': '0.5rem',
                        '--Step-connectorRadius': '1rem',
                        '--Step-connectorThickness': '4px',
                        '--joy-palette-success-solidBg': 'var(--joy-palette-success-400)',
                        [`& .${stepClasses.completed}`]: {
                            '&::after': { bgcolor: 'success.solidBg' },
                            [`& .${typographyClasses.root}`]: {
                                color: mode.mode === 'dark' ? "white" : 'neutral',
                            },
                        },
                        [`& .${stepClasses.active}`]: {
                            [`& .${stepIndicatorClasses.root}`]: {
                                border: '4px solid',
                                borderColor: '#fff',
                                boxShadow: `0 0 0 1px ${theme.vars.palette.primary[500]}`,
                            },
                            [`& .${typographyClasses.root}`]: {
                                color: mode.mode === 'dark' ? "white" : 'neutral',
                            },
                        },
                        [`& .${stepClasses.disabled} *`]: {
                            color: 'neutral.softDisabledColor',
                        },
                        [`& .${typographyClasses['title-sm']}`]: {
                            textTransform: 'uppercase',
                            letterSpacing: '1px',
                            fontSize: '10px',
                        },
                    })}
                >
                    <Step
                        disabled={started ? false : (step1completed ? false : true)}
                        active={started ? (step1completed ? false : true) : false}
                        completed={step1completed ? true : false}
                        indicator={
                            <StepIndicator variant={step1completed ? 'solid' : 'soft'} color={step1completed ? 'success' : 'neutral'}>
                                <CloudUploadIcon />
                            </StepIndicator>
                        }
                    >
                        <div>
                            <Typography level="title-sm">Step 1</Typography>
                            <Typography>File Upload</Typography>
                        </div>
                    </Step>

                    <Step
                        disabled={step1completed ? false : true}
                        active={step1completed ? (step2completed ? false : true) : false}
                        completed={step2completed ? true : false}
                        indicator={
                            <StepIndicator variant={step2completed ? 'solid' : 'soft'} color={step2completed ? 'success' : 'neutral'}>
                                <AssignmentIndIcon />
                            </StepIndicator>
                        }
                    >
                        <div>
                            <Typography level="title-sm">Step 2</Typography>
                            <Typography>Face Recognition</Typography>
                        </div>
                    </Step>

                    <Step
                        disabled={step2completed ? false : true}
                        active={step2completed ? (step3completed ? false : true) : false}
                        completed={step3completed ? true : false}
                        indicator={
                            <StepIndicator variant={step3completed ? 'solid' : 'soft'} color={step3completed ? 'success' : 'neutral'}>
                                <TroubleshootIcon />
                            </StepIndicator>
                        }
                    >
                        <div>
                            <Typography level="title-sm">Step 3</Typography>
                            <Typography>Personality Analysis</Typography>
                        </div>
                    </Step>

                    <Step
                        disabled={step3completed ? false : true}
                        active={step3completed ? (step4completed ? false : true) : false}
                        completed={step4completed ? true : false}
                        indicator={
                            <StepIndicator variant={step4completed ? 'solid' : 'soft'} color={step4completed ? 'success' : 'neutral'}>
                                <PieChartIcon />
                            </StepIndicator>
                        }
                    >
                        <div>
                            <Typography level="title-sm">Step 4</Typography>
                            <Typography>Results</Typography>
                        </div>
                    </Step>
                </Stepper>
            </CssVarsProvider>
        </Box>
    );
};

export default UploadStepper;
