import React, { useMemo } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppAppBar from './components/AppAppBar';
import Footer from './components/Footer';
import Main from './components/Main';
import getMPTheme from './theme/getMPTheme';
import { Divider } from '@mui/material';
import Login from './components/Login';

export default function Signin() {
  const [mode, setMode] = React.useState('light');

  React.useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    } else {
      const systemPrefersDark = window.matchMedia(
        '(prefers-color-scheme: dark)',
      ).matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    localStorage.setItem('themeMode', newMode);
  };

  // Crea dinamicamente il tema MUI in base al valore di `mode`
  const muiTheme = useMemo(() => createTheme(getMPTheme(mode)), [mode]);

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline enableColorScheme />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Login />
      <Divider />
      <Footer />
    </ThemeProvider>
  );
}
