import React, { createContext, useContext, useState, useEffect } from 'react';

const decodeJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

const isTokenExpired = (token) => {
  if (!token) return true;
  const decodedToken = decodeJwt(token);
  const currentTime = Math.floor(Date.now() / 1000);
  return decodedToken.exp < currentTime;
};

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('authToken'));
  const [isLoading, setIsLoading] = useState(true); // Aggiunto stato di caricamento

  useEffect(() => {
    const storedToken = localStorage.getItem('authToken');
    if (storedToken && !isTokenExpired(storedToken)) {
      setIsAuthenticated(true);
      setUserData(decodeJwt(storedToken));
      setToken(storedToken);
    } else {
      setIsAuthenticated(false);
      setUserData(null);
      setToken(null);
    }
    setIsLoading(false); // Aggiorna lo stato di caricamento una volta che la logica di autenticazione è finita
  }, []);

  const login = (data) => {
    const { token, user } = data;
    setToken(token);
    setIsAuthenticated(true);
    setUserData(user);
    localStorage.setItem('authToken', token);
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUserData(null);
    setToken(null);
    localStorage.removeItem('authToken');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userData, login, logout, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
