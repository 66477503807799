import React, { useEffect, useState } from 'react';
import { Modal, Box, Sheet, ModalClose } from '@mui/joy';

const Modalform = ({ openModal, setOpenModal }) => {

    const [iframeSrc, setIframeSrc] = useState('');


    useEffect(() => {
        const loadTallyScript = () => {
          const script = document.createElement('script');
          script.src = 'https://tally.so/widgets/embed.js';
          script.async = true;
          document.body.appendChild(script);
        };
    
        if (openModal) {
          // Imposta l'URL dell'iframe quando la modale si apre
          setIframeSrc('https://tally.so/r/ward5y?transparentBackground=1');
          loadTallyScript(); // Carica lo script solo quando la modale è aperta
        }
    
        // Reset dell'iframe quando la modale viene chiusa
        return () => {
          setIframeSrc('');
        };
      }, [openModal]);
      
    
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={openModal}
      onClose={() => setOpenModal(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Sheet
        variant="outlined"
        sx={{ borderRadius: 'md', p: 3, boxShadow: 'lg', padding: '10px !important' }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />
        <Box
          sx={{
            width: '80vw', // Larghezza del 80% della viewport
            height: '90vh', // Altezza del 90% della viewport
            borderRadius: 'md',
            overflow: 'hidden', // Nasconde eventuali contenuti in eccesso
            display: 'flex', // Per centrare il contenuto
            flexDirection: 'column', // Disposizione verticale
          }}
        >
          {iframeSrc && (
            <iframe
              src={iframeSrc} // Usa iframeSrc per impostare la sorgente
              width="100%"
              height="100%" // Altezza dell'iframe al 100% del contenitore
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              title="StaffMotion Demo"
              style={{ border: 'none' }}
            />
          )}
        </Box>
      </Sheet>
    </Modal>
  );
};

export default Modalform;
