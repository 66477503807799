import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import App from './MarketingPage';
import reportWebVitals from './reportWebVitals';
import Demo from './Demo';
import Signin from './Signin';
import Privacy from './Privacy';
import Terms from './Terms';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/demo",
    element: <PrivateRoute element={<Demo />} />, // Usa il PrivateRoute qui
  },
  {
    path: "/signin",
    element: <Signin />,
  },
  {
    path: "/privacy-policy",
    element: <Privacy/>
  },
  {
    path: "/terms",
    element: <Terms/>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
