import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { CssVarsProvider } from '@mui/joy/styles';
import { getJoyDesignTokens } from '../theme/joyTheme'; // Importa il tema Joy
import Modalform from './Modalform';
import { ReactTyped } from "react-typed";


export default function Hero({ mode, toggleColorMode }) {

  const words = ['potential', 'creativity', 'collaboration', 'innovation']; // Le parole da animare
  const [openModal, setOpenModal] = React.useState(false);

  const joyTheme = useMemo(() => getJoyDesignTokens(mode), [mode]);

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={3}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '80%' }, mt: '20vh', mb: "25vh" }}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { md: 'column', lg:'row', sm:'column', xs:'column', xl:'row'},
              alignItems: 'center',
              fontSize: 'clamp(2.5rem, 10vw, 3.5rem)',
            }}
          >
            <span style={{ whiteSpace: 'nowrap' }}>Unlock&nbsp;your&nbsp;team's&nbsp;</span>
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: 'inherit',
                color: 'primary.main',
                position: 'relative', // Imposta la posizione relativa
                ...theme.applyStyles('dark', {
                  color: 'primary.light',
                }),
                minWidth: '340px', // Imposta una larghezza minima per evitare il movimento
              })}
            >
              <ReactTyped
                strings={words}
                typeSpeed={100}
                backSpeed={100}
                backDelay={4000} 
                loop
                style={{ display: 'inline-block' }} // Mantiene ReactTyped su una linea
              />
            </Typography>
          </Typography>


          <Typography
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              width: { sm: '100%', md: '80%' },
            }}
          >
            Revolutionize your team's success with&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: 'inherit',
                color: 'primary.main',
                ...theme.applyStyles('dark', {
                  color: 'primary.light',
                }),
              })}
            >Staff Motion</Typography>: tap into powerful Big Five personality insights from video calls to fuel unstoppable collaboration and growth
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: '350px' }, alignItems: "center", justifyContent: "center" }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ minWidth: 'fit-content' }}
              onClick={() => setOpenModal(true)}
            >
              Get Early Access
            </Button>
          </Stack>
          {/*<Typography
            variant="caption"
            color="text.secondary"
            sx={{ textAlign: 'center' }}
          >
            By clicking &quot;Early Access&quot; you agree to our&nbsp;
            <Link href="#" color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography>*/}
        </Stack>
      </Container>
      <CssVarsProvider theme={joyTheme}>
        <Modalform
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      </CssVarsProvider>
    </Box>
  );
}
