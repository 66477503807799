import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const PrivateRoute = ({ element }) => {
  const { isAuthenticated, isLoading } = useAuth();
  
  // Se il controllo del token è ancora in corso, non fare nulla
  if (isLoading) {
    return <div>Loading...</div>; // Puoi aggiungere una UI di caricamento se necessario
  }

  // Se l'utente non è autenticato, reindirizza a /signin
  if (!isAuthenticated) {
    return <Navigate to="/signin" replace />;
  }

  // Se l'utente è autenticato, mostra il componente richiesto
  return element;
};

export default PrivateRoute;
