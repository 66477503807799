import React, { useMemo, useRef } from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Button from '@mui/joy/Button';
import SvgIcon from '@mui/joy/SvgIcon';
import { styled as styledJoy } from '@mui/joy';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/joy/Snackbar';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import Tooltip from '@mui/joy/Tooltip';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import CircularProgress from '@mui/joy/CircularProgress';
import { CssVarsProvider } from '@mui/joy/styles';
import { getJoyDesignTokens } from '../theme/joyTheme'; // Importa il tema Joy
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion, { accordionClasses } from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionSummary from '@mui/joy/AccordionSummary';
import UploadStepper from './Stepper';
import AWS from 'aws-sdk';
import { useAuth } from '../AuthContext';
import { Modal, Sheet, ModalClose } from '@mui/joy';

const VisuallyHiddenInput = styledJoy('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;


export default function Main(mode) {
    const { userData } = useAuth();
    const [ws, setWs] = useState(null); // Stato per la WebSocket
    const [connected, setConnected] = useState(false); // Stato di connessione WebSocket
    const [startEffect, setStartEffect] = useState(false); // Stato per controllare quando partire con useEffect
    const [connectionID, setConnectionID] = useState('');
    const [resultsurl, setResultsurl] = useState('')
    const [resultsjson, setResultsjson] = useState('');
    const [openModal, setOpenModal] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    const [errormessage, setErrormessage] = useState('');
    const duration = 3000;
    const [left, setLeft] = React.useState();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false); // Stato per disabilitare il button
    const [file, setFile] = useState('');
    const [started, setStarted] = useState(false);
    //Stati per gestire lo stepper
    const [step1completed, setStep1completed] = useState(false);
    const [step2completed, setStep2completed] = useState(false);
    const [step3completed, setStep3completed] = useState(false);
    const [step4completed, setStep4completed] = useState(false);

    const [logs, setLogs] = useState([]);
    const [uuid, setUuid] = useState('');

    const joyTheme = useMemo(() => getJoyDesignTokens(mode), [mode]);

    const timer = React.useRef(undefined);

    const countdown = () => {
        timer.current = setInterval(() => {
            setLeft((prev) => (prev === undefined ? prev : Math.max(0, prev - 100)));
        }, 100);
    };

    React.useEffect(() => {
        if (open && duration !== undefined && duration > 0) {
            setLeft(duration);
            countdown();
        } else {
            window.clearInterval(timer.current);
        }
    }, [open, duration]);

    const handlePause = () => {
        window.clearInterval(timer.current);
    };
    const handleResume = () => {
        countdown();
    };

    const step1CompletedRef = useRef(step1completed);
    const step2CompletedRef = useRef(step2completed);

    // Primo useEffect per aggiornare i ref con i valori più recenti
    useEffect(() => {
        step1CompletedRef.current = step1completed;
        step2CompletedRef.current = step2completed;
    }, [step1completed, step2completed]);


    useEffect(() => {
        let socket;

        if (startEffect) {
            console.log("È true");
            const url = "wss://2rdb73oulk.execute-api.eu-north-1.amazonaws.com/production/";
            socket = new WebSocket(url + "?uuid=" + userData.uuid);

            socket.onopen = () => {
                console.log("WebSocket connection established");
                setConnected(true);
            };

            socket.onmessage = (event) => {
                const data = JSON.parse(event?.data);
                console.log(data?.message);
                try {
                    if (data?.message?.connectionID) {
                        setConnectionID(data?.message?.connectionID);
                        uploadVideo(file, data?.message?.connectionID);
                    } else {
                        const currentDateTime = new Date().toLocaleString();
                        setLogs((prevLogs) => [...prevLogs, `[${currentDateTime}] ${data?.message?.log}`]);
                        if (step1CompletedRef.current && data?.message?.container_name === 'splitframes' && data?.message?.status === "STOPPED") {
                            setStep2completed(true);
                        } else if (step2CompletedRef.current && data?.message?.container_name === 'prediction' && data?.message?.status === "STOPPED") {
                            setStep3completed(true);
                        } else if (step2CompletedRef.current && data?.message?.status === "Finished" && data?.message?.url !== '') {
                            setStep3completed(true);
                            setStep4completed(true);
                            setResultsurl(data?.message?.url);
                        }

                    }
                } catch (error) {
                    console.log(error);
                }
            };

            socket.onerror = (error) => {
                console.error("WebSocket Error:", error);
            };

            socket.onclose = () => {
                console.log("WebSocket connection closed");
                setConnected(false);
            };

            setWs(socket);

            return () => {
                if (ws) {
                    ws.close();
                }
            };
        } else {
            console.log("È false");
        }
        return () => {
            if (socket) {
                socket.close();
                console.log('WebSocket closed during cleanup');
            }
        };

    }, [startEffect]); // Dipendenza da startEffect

    useEffect(() => {
        // Funzione per recuperare e leggere il JSON
        if (resultsurl !== '') {
            const fetchData = async () => {
                try {
                    const response = await fetch(resultsurl);
                    if (!response.ok) {
                        throw new Error('Failed to fetch JSON');
                    }
                    const data = await response.json();
                    setResultsjson(data);  // Salva i dati nel state
                    setOpenModal(true);
                    console.log(data);
                } catch (error) {
                    console.error("Error fetching the JSON file:", error);
                }
            };

            fetchData();  // Chiamata per ottenere i dati
        }
    }, [resultsurl]);


    const uploadVideo = async (file, connectionID) => {
        const currentDateTime = new Date().toLocaleString(); // Ottieni data e ora
        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            region: process.env.REACT_APP_AWS_REGION,
        });
        const s3 = new AWS.S3();

        const params = {
            Bucket: 'video-staffmotion', // Inserisci il nome del bucket S3
            Key: `${file.name}`, // Nome con cui salvare il file in S3
            Body: file,
            ContentType: file.type,
            Metadata: {
                'connectionid': connectionID
            }
        };
        setLogs((prevLogs) => [...prevLogs, `[${currentDateTime}] File uploading...`]);
        s3.upload(params, (err, data) => {
            if (err) {
                console.log('Errore durante il caricamento:', err);
                setLogs((prevLogs) => [...prevLogs, `[${currentDateTime}] Upload failed: ${err}`]);
            } else {
                setStep1completed(true); // Completa lo Step 1
                setLogs((prevLogs) => [...prevLogs, `[${currentDateTime}] Upload successful`]);
            }
        });
    };


    // Funzione per inviare un messaggio tramite WebSocket
    const sendMessage = (message) => {
        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(message);
            console.log("websocket connected")
        } else {
            console.log("WebSocket is not connected");
        }
    };

    return (
        <Box
            id="hero"
            sx={(theme) => ({
                width: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundImage:
                    'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
                ...theme.applyStyles('dark', {
                    backgroundImage:
                        'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
                }),
            })}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: { xs: 14, sm: 10 },
                    pb: { xs: 8, sm: 12 },
                }}
            >
                <Stack
                    spacing={20}
                    direction="row"
                    useFlexGap
                    sx={{ alignItems: 'center', width: { xs: '100%', sm: '100%' }, mt: '20vh', mb: "25vh", justifyContent: "center" }}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <CssVarsProvider theme={joyTheme}>
                            <Button
                                disabled={isButtonDisabled}
                                component="label"
                                role={undefined}
                                tabIndex={-1}
                                variant="outlined"
                                color={open ? "danger" : 'neutral'}
                                startDecorator={
                                    <SvgIcon>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke={open ? '#c41c1c' : (isButtonDisabled ? '#9fa6ad' : (mode.mode === 'dark' ? "white" : "black"))}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                            />
                                        </svg>
                                    </SvgIcon>
                                }
                                sx={{
                                    '&:hover': {
                                        backgroundColor: mode.mode === 'dark' ? 'transparent' : '', // Colore di sfondo durante l'hover
                                    },
                                }}
                            ><Typography
                                component="span"
                                variant="h5"
                                sx={(theme) => {
                                    return {
                                        color: mode.mode === 'dark'
                                            ? `rgba(255, 255, 255, 0.8)`
                                            : `neutral`,
                                    };
                                }}
                            >
                                    Upload a video
                                </Typography>
                                <VisuallyHiddenInput
                                    type="file"
                                    accept="video/mp4,video/avi,video/mkv,video/webm"
                                    onChange={(event) => {
                                        const file = event.target.files[0];
                                        if (file) {
                                            const validExtensions = ['mp4', 'avi', 'mkv', 'webm'];
                                            const fileExtension = file.name.split('.').pop().toLowerCase();
                                            const maxSize = 100 * 1024 * 1024; // 100 MB in byte

                                            if (!validExtensions.includes(fileExtension)) {
                                                // Se l'estensione non è valida, mostra l'errore
                                                setErrormessage('Upload a valid video');
                                                setOpen(true);
                                            } else if (file.size > maxSize) {
                                                // Se la dimensione del file è maggiore di 100 MB, mostra l'errore
                                                setErrormessage('The selected video exceeds the maximum file size of 100 MB');
                                                setOpen(true);
                                            } else {
                                                // Se il file è valido, resetta l'errore e disabilita il button
                                                setFile(file);
                                                setIsButtonDisabled(true); // Disabilita il button dopo l'upload
                                            }
                                        }
                                    }}
                                />

                            </Button>
                        </CssVarsProvider>
                        {file?.name && (
                            <Container sx={(theme) => ({
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '30px',
                                mt: '20px',
                            })}>
                                <Box sx={(theme) => ({
                                    border: '1px #d9d9d9',
                                    borderRadius: '5px',
                                    borderStyle: 'dotted',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '10px',
                                    padding: '5px 15px',
                                    ...theme.applyStyles('dark', {
                                        border: '1px dotted secondary',
                                    })
                                })}>
                                    <DeleteOutlineOutlinedIcon onClick={() => {
                                        setFile(''); // Resetta il nome del file
                                        setIsButtonDisabled(false); // Riabilita il button
                                    }}
                                        sx={{
                                            cursor: "pointer",
                                            fontSize: 17,
                                        }} />
                                    <Typography
                                        component="span"
                                        variant="h5"
                                        sx={(theme) => ({
                                            fontSize: 'inherit',
                                            color: 'primary',
                                            ...theme.applyStyles('dark', {
                                                color: 'primary.light',
                                            }),
                                        })}
                                    >
                                        {file?.name}
                                    </Typography>
                                </Box>

                            </Container>


                        )}
                        <CssVarsProvider theme={joyTheme}>
                            <Snackbar
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                variant="solid"
                                color="danger"
                                startDecorator={<PlayCircleOutlineIcon />}
                                autoHideDuration={duration}
                                resumeHideDuration={left}
                                onMouseEnter={handlePause}
                                onMouseLeave={handleResume}
                                onFocus={handlePause}
                                onBlur={handleResume}
                                onUnmount={() => setLeft(undefined)}
                                open={open}
                                onClose={() => {
                                    setOpen(false);
                                }}
                            >
                                {errormessage}
                            </Snackbar>
                        </CssVarsProvider>
                    </Box>
                    {(file?.name && (!started || step4completed)) && (
                        <Box
                            sx={{
                                ml: '-100px',
                            }}
                        >
                            <CssVarsProvider theme={joyTheme}>
                                <Tooltip
                                    title="Start Staff Motion"
                                    sx={{
                                        fontSize: 10,
                                    }}
                                >
                                    <PlayCircleFilledIcon
                                        sx={{
                                            cursor: "pointer",
                                            fontSize: 50,
                                            color: "primary.main",
                                        }}
                                        onClick={() => {
                                            setLogs([]);
                                            setStarted(true);
                                            //Controllo che siano tutti a false i valori degli state importanti
                                            setStep1completed(false);
                                            setStep2completed(false);
                                            setStep3completed(false);
                                            setStep4completed(false);
                                            //Qui ci sarà da far partire upload del video
                                            if (file) {
                                                setStartEffect(true);
                                                //sendMessage("ciao");
                                                //uploadVideo(file); // Passa il file selezionato alla funzione uploadVideo
                                            } else {
                                                console.error("Nessun file selezionato.");
                                            }
                                        }}
                                    />
                                </Tooltip>
                            </CssVarsProvider>
                        </Box>
                    )}
                    {(file?.name && started && !step4completed) && (
                        <Box
                            sx={{
                                ml: '-100px',
                                mr: '30px',
                            }}
                        >

                            <Box
                                sx={{
                                    width: '20px',
                                }}
                            >
                                <CssVarsProvider theme={joyTheme}>
                                    <CircularProgress
                                        thickness={4}
                                        sx={{
                                            zIndex: 999999,
                                            "--CircularProgress-size": "50px",
                                            color: "primary.main",
                                        }}
                                    >

                                        <Tooltip
                                            title="Stop Staff Motion"
                                            sx={{
                                                fontSize: 10,
                                            }}
                                        >
                                            <StopCircleIcon
                                                sx={{
                                                    cursor: "pointer",
                                                    fontSize: 50,
                                                    color: "primary.main",
                                                    zIndex: 2, // Assicurati che l'icona sia sopra il progresso
                                                }}
                                                onClick={() => {
                                                    setStarted(false);
                                                }}
                                            />
                                        </Tooltip>

                                    </CircularProgress>
                                </CssVarsProvider>
                            </Box>

                        </Box>

                    )

                    }
                    <UploadStepper
                        file={file}
                        joyTheme={joyTheme}
                        mode={mode}
                        started={started}
                        step1completed={step1completed}
                        step2completed={step2completed}
                        step3completed={step3completed}
                        step4completed={step4completed}
                        setStep1completed={setStep1completed}
                        setStep2completed={setStep2completed}
                        setStep3completed={setStep3completed}
                        setStep4completed={setStep4completed}
                    />
                </Stack>

                <Box sx={{
                    width: '100%',
                }}>
                    <CssVarsProvider theme={joyTheme}>
                        <AccordionGroup
                            sx={(theme) => ({
                                width: '100%',
                                [`& .${accordionClasses.root}`]: {
                                    marginTop: '0.5rem',
                                    transition: '0.2s ease',
                                    '& button:not([aria-expanded="true"])': {
                                        transition: '0.2s ease',
                                        paddingBottom: '0.625rem',
                                    },
                                    '& button:hover': {
                                        background: 'transparent',
                                    },
                                },
                                [`& .${accordionClasses.root}.${accordionClasses.expanded}`]: {
                                    bgcolor: 'background.level1',
                                    borderRadius: 'md',
                                    borderBottom: '1px solid',
                                    borderColor: 'background.level2',
                                },
                                '& [aria-expanded="true"]': {
                                    boxShadow: `inset 0 -1px 0 ${theme.vars.palette.divider}`,
                                },
                            })}
                        >
                            <Accordion>
                                <AccordionSummary
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',  // Spaziatura uniforme tra testo e icona
                                        alignItems: 'center',             // Allinea verticalmente il contenuto
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            flexGrow: 1,                   // Permette al testo di espandersi per occupare spazio
                                            textAlign: 'center',           // Centra il testo orizzontalmente
                                        }}
                                    >
                                        Show Logs
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {logs.map((log, index) => (
                                        <Typography key={index} variant="body4" sx={{ mb: 1, fontSize: "0.9rem" }}>
                                            {log}
                                        </Typography>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        </AccordionGroup>
                    </CssVarsProvider>
                </Box>
                {resultsjson !== '' && (<CssVarsProvider theme={joyTheme}><Modal
                    aria-labelledby="modal-title"
                    aria-describedby="modal-desc"
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <Sheet
                        variant="outlined"
                        sx={{ borderRadius: 'md', p: 3, boxShadow: 'lg', padding: '10px !important' }}
                    >
                        <ModalClose variant="plain" sx={{ m: 1 }} />
                        <Box
                            sx={{
                                width: '40vw', // Larghezza del 80% della viewport
                                height: '80vh', // Altezza del 90% della viewport
                                borderRadius: 'md',
                                overflow: 'auto', // Nasconde eventuali contenuti in eccesso
                                display: 'flex', // Per centrare il contenuto
                                flexDirection: 'column', // Disposizione verticale
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                variant={'h2'}
                                mt={5}
                                mb={5}
                            >Staff Motion Results</Typography>
                            {Object.entries(resultsjson).map(([name, traits]) => (
                                <div key={name}>
                                    <Typography variant="h4" mt={5}>{name}</Typography>
                                    {Object.entries(traits).map(([trait, value]) => (
                                        <Typography variant="h5" key={trait}>
                                            {trait}: {value}
                                        </Typography>
                                    ))}
                                </div>
                            ))}
                        </Box>
                    </Sheet>
                </Modal></CssVarsProvider>)}
            </Container>
        </Box >
    );
}
